import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from 'firebase/storage';

// Firebase Creditials Object
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_KEY,
    authDomain: process.env.REACT_APP_FB_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGE_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

if (process.env.REACT_APP_LOCAL_EMULATOR === "true") {
    console.debug("RUNNING LOCAL EMULATOR");
    connectFirestoreEmulator(db, '127.0.0.1', process.env.REACT_APP_FIRESTORE_EMULATOR_PORT);
    connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST);
    connectStorageEmulator(storage, "127.0.0.1", 9199);
}

export { db, auth, storage };