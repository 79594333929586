import React, { useEffect } from 'react'
import logoColoured from '../../../assets/common/img/nuroColur.png';
import { Link } from 'react-router-dom';
import { FRONTEND_ROUTES } from '../../../constants/RouteConst';

const Footer = () => {

    useEffect(() => {
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        const script = document.createElement("script");
        script.async = true;
        script.src = 'https://embed.tawk.to/65d4367f9131ed19d96ed786/1hn2f9phd';
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        document.getElementsByTagName("body")[0].appendChild(script);
    }, [])

    return (
        <>
            <div className="container-fluid sectionOneFluid footerFluid">
                <div className="container">
                    <div className="row py-sm-5 py-3">
                        <div className="col-12 mb-md-5 mb-3 px-0">
                            <div className="logo">
                                <Link to={FRONTEND_ROUTES.HOME_PAGE}>
                                    <img src={logoColoured} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="row mx-0">
                                <div className="col-12 px-0"><div className="address">SoundVida, Inc, 26565 West Agoura Road, Suite 200, Calabasas, California, 91302</div></div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-4 mt-md-0">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-md-center justify-content-start">
                                    <ul className='navlinks m-0 p-p  list-unstyled'>
                                        <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.JOURNALS}>Resources</Link></li>
                                        <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.BLOGS}>Neuromonics Blogs</Link></li>
                                        <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.PRIVACYPOLICY}>Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6 mt-4 mt-md-0">
                            <div className="row">
                                <div className="col-12  d-flex justify-content-md-center justify-content-start">
                                    <ul className='navlinks m-0 p-p  list-unstyled'>
                                        <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.TINNITUS}>What is Tinnitus?</Link></li>
                                        <li className='navLi mb-2'><Link className='navAnchor text-dark' to={FRONTEND_ROUTES.NEUROMONICS}>Neuromonics</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pb-4 pb-sm-4">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="copyRyt">Copyright © 2023, SoundVida, Inc.  All rights reserved.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer