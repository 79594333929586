import React, { useEffect, useState } from 'react'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import { getAuth, signInAnonymously, signOut } from 'firebase/auth';
import { collection, setDoc, doc, serverTimestamp, getDoc, arrayUnion, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../common/firebase';
import { ROLES, ROLE_PRODUCTS } from '../../constants/RoleConst';
import Alert from '../../components/alert/Alert';
import { RandomCodeGenerator, checkEmailExists, getTemplateContent, firestoreTransaction } from '../../common/helper';
import Loader from '../extra/Loader';
import { BASE_URL, sendEmail, setRoleData } from '../../common/fetch';
import { TEMPPLATETYPE } from '../../constants/TemplateTypeConst';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';
//import { Navigate, useNavigate } from 'react-router-dom';
import { PUBLIC_KEY } from '../../constants/PaymentConst';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { v4 as uuidv4 } from 'uuid';


const AddPatient = () => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [purchase, setPurchase] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [ selectedCurrency,setSelectedCurrency  ] = useState("");

    //const navigator = useNavigate();

    const availableProducts = ROLE_PRODUCTS[localStorage.getItem('role')].map((p) => <option key={p.value} value={p.value} >{p.name}</option>);

    useEffect(() => {
        const clearMessages = () => {
            setErrorMsg('');
            setSuccessMsg('');
        };
        const timer = setTimeout(clearMessages, 3000);
        return () => clearTimeout(timer);
    }, [errorMsg, successMsg]);

    useEffect(() => {
        console.log(purchase)
        if (purchase !== '' && purchase !== undefined && purchase !== null) {
            setIsButtonDisabled(false);
        }
        else {
            setIsButtonDisabled(true);
        }

    }, [purchase, isButtonDisabled])

    function replaceUrls(template, sessionUrl) {
        return template.replace(/href="[^"]*"/g, `href="${sessionUrl}"`);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMsg('');
        setErrorMsg('');
        try {
            const isValidEmail = (email) => {
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailRegex.test(email);
            };
            const validationRules = [
                { field: firstName, message: 'The first name field must not be left blank.' },
                { field: lastName, message: 'The last name field must not be left blank.' },
                { field: email, message: 'The email address field must not be left blank.' },
                { field: purchase, message: 'Please select the payment type.' },
                { field: selectedCurrency, message: 'Please select a currency.' },
            ];

            for (const rule of validationRules) {
                if (!rule.field) {
                    setErrorMsg(rule.message);
                    return false;
                }
            }
            if (!isValidEmail(email)) {
                setErrorMsg('Invalid email format. Please enter a valid email address.');
                return false;
            }
            const auth = getAuth();
            const checkClientEmailExists = await checkEmailExists(email);            
            if (!checkClientEmailExists) {
                await signInAnonymously(auth)
                    .then(async (userCredential) => {
                        setIsLoading(true);
                        const user = userCredential.user;
                        const userId = user.uid;
                        const defaultRole = ROLES.CLIENT;
                        await setRoleData(userId, defaultRole);
                        const activationCode = RandomCodeGenerator();
                        await firestoreTransaction(setDoc, doc(collection(db, DATABASECOLLECTION.USERPROFILES),userId), {
                            firstName: firstName,
                            lastName: lastName,
                            emailAddress: email,
                            isSubscribed:false,
                            stripeLogicId: uuidv4(),
                            created_at: serverTimestamp(),
                            updated_at: serverTimestamp(),
                        });
                        const userProfileDocRef = doc(collection(db, DATABASECOLLECTION.USERPROFILES), userId);
                        const otherSettingsCollectionRef = collection(userProfileDocRef, DATABASECOLLECTION.OTHERSETTINGS);
                        const activationCodeDocRef = doc(otherSettingsCollectionRef, DATABASECOLLECTION.ACTIVATIONCODE);
                        const activationCodeSnapshot = await firestoreTransaction(getDoc, activationCodeDocRef);
                        if (!activationCodeSnapshot.exists()) {
                            await firestoreTransaction(setDoc, activationCodeDocRef, {
                                activationCode: activationCode,
                                clinicianUID: doc(db, `${DATABASECOLLECTION.USERPROFILES}/${localStorage.getItem('docid')}`),
                            });
                        }

                        //create a document under the activationCodes collection
                        const activationCodesCollectionRef = collection(db, DATABASECOLLECTION.ACTIVATIONCODES);
                        const activationCodeDocUserRef = doc(activationCodesCollectionRef, activationCode);
                        //const activationCodeUserSnapshot = await getDoc(activationCodeDocUserRef);
                        const activationCodeUserSnapshot = await firestoreTransaction(getDoc, activationCodeDocUserRef);
                        if (!activationCodeUserSnapshot.exists()) {
                            // await setDoc(activationCodeDocUserRef, {
                            //     clientUID: doc(db, `${DATABASECOLLECTION.USERPROFILES}/${userId}`),
                            //     clinicianUID: doc(db, `${DATABASECOLLECTION.USERPROFILES}/${localStorage.getItem('docid')}`),
                            //     created_at: serverTimestamp(),
                            //     isUsed: false,
                            // });
                            await firestoreTransaction(setDoc, activationCodeDocUserRef, {
                                clientUID: doc(db, `${DATABASECOLLECTION.USERPROFILES}/${userId}`),
                                clinicianUID: doc(db, `${DATABASECOLLECTION.USERPROFILES}/${localStorage.getItem('docid')}`),
                                created_at: serverTimestamp(),
                                isUsed: false,
                            });
                        }

                        //Add clientUID under the userProfile of clinician collection
                        const userProfileClinicianDocRef = doc(collection(db, DATABASECOLLECTION.USERPROFILES), localStorage.getItem('docid'));
                        const otherSettingsClinicianCollectionRef = collection(userProfileClinicianDocRef, DATABASECOLLECTION.OTHERSETTINGS);
                        const clientUIDDocRef = doc(otherSettingsClinicianCollectionRef, 'clientUID');
                        //const clientUIDDocSnapshot = await getDoc(clientUIDDocRef);
                        const clientUIDDocSnapshot = await firestoreTransaction(getDoc, clientUIDDocRef);
                        if (clientUIDDocSnapshot.exists()) {
                            // await setDoc(clientUIDDocRef, {
                            //     clientUIDs: arrayUnion(doc(db, `${DATABASECOLLECTION.USERPROFILES}/${userId}`)),
                            // }, { merge: true });
                            await firestoreTransaction(setDoc, clientUIDDocRef, {
                                clientUIDs: arrayUnion(doc(db, `${DATABASECOLLECTION.USERPROFILES}/${userId}`)),
                            }, { merge: true });
                        } else {
                            // await setDoc(clientUIDDocRef, {
                            //     clientUIDs: [doc(db, `${DATABASECOLLECTION.USERPROFILES}/${userId}`)],
                            // });
                            await firestoreTransaction(setDoc, clientUIDDocRef, {
                                clientUIDs: [doc(db, `${DATABASECOLLECTION.USERPROFILES}/${userId}`)],
                            })
                        }

                        const getClinicianProfile = doc(collection(db, DATABASECOLLECTION.USERPROFILES), localStorage.getItem('docid'));
                        //const clinicianSnapshot = await getDoc(getClinicianProfile);
                        const clinicianSnapshot = await firestoreTransaction(getDoc, getClinicianProfile);
                        const getClinicianData = clinicianSnapshot.data();

                        // const clinicianTemplate = await getTemplateContent(TEMPPLATETYPE.ADDCLIENTC);
                        // const templateAdminContent = clinicianTemplate[0].content;
                        // const updatedEmailAdminContent = templateAdminContent.replace(/%%FirstName%%/g, firstName)
                        // .replace(/%%LastName%%/g, lastName)
                        // .replace(/%%ClinicianName%%/g, getClinicianData.firstName + ' ' + getClinicianData.lastName)
                        // .replace(/%%Email%%/g, email)
                        // .replace(/%%ActivationCode%%/g, activationCode);
                        
                        //const userProfileClinicianDocData = await getDoc(userProfileClinicianDocRef);
                        const userProfileClinicianDocData = await firestoreTransaction(getDoc, userProfileClinicianDocRef);
                        const userprofileData = userProfileClinicianDocData.data();
                        // const clientTemplate = await getTemplateContent(TEMPPLATETYPE.ADDCLIENT);
                        // const templateContent = clientTemplate[0].content;
                        // const updatedEmailContent = templateContent.replace(/%%FirstName%%/g, firstName)
                        //     .replace(/%%LastName%%/g, lastName)
                        //     .replace(/%%ClinicianName%%/g, getClinicianData.firstName + ' ' + getClinicianData.lastName)
                        //     .replace(/%%Email%%/g, email)
                        //     .replace(/%%ActivationCode%%/g, activationCode);

                        // await sendEmail(email, clientTemplate[0].subject, updatedEmailContent, clientTemplate[0].bcc);
                        // await sendEmail(userprofileData.emailAddress, clinicianTemplate[0].subject, updatedEmailAdminContent, clinicianTemplate[0].bcc);

                        //Send the email on basis of payment type selection
                        const paymentOptionType = purchase === 'purchase1' || purchase === 'purchase2' ? 'WHOLESALE' : purchase === 'purchase3' ? 'UPFRONT' : purchase === 'purchase4' ? 'PURCHASE':'NOFEE';
                        const clientTemplatePay = await getTemplateContent(TEMPPLATETYPE.CLINICIANPURCHASEORDER);
                        const templateAdminContentPay = clientTemplatePay[0].content;
                        const updatedEmailAdminContentPay = templateAdminContentPay
                            .replace(/%%FirstName%%/g, firstName)
                            .replace(/%%LastName%%/g, lastName)
                            .replace(/%%Email%%/g, email)
                            .replace(/%%ActivationCode%%/g, activationCode)
                            .replace(/%%ClinicianName%%/g, getClinicianData.firstName + ' ' + getClinicianData.lastName)
                            .replace(/%%PAYMENTOPTION%%/g, '')
                            .replace(/%%WHOLESALE%%(.*?)%%WHOLESALE%%/gs, paymentOptionType === 'WHOLESALE' ? '$1' : '')
                            .replace(/%%UPFRONT%%(.*?)%%UPFRONT%%/gs, paymentOptionType === 'UPFRONT' ? '$1' : '')
                            .replace(/%%PURCHASE%%(.*?)%%PURCHASE%%/gs, paymentOptionType === 'PURCHASE' ? '$1' : '');
                        if (purchase === "purchase3" || purchase === "purchase4" ) {

                            const res = await axios.post(`${BASE_URL}checkOutSession`, { uid: userId,purchase,installments:purchase==="purchase3"?false:true,currencyType:selectedCurrency });

                            const sessionUrl = res?.data?.data?.session_id?.url;

                            // const updatedEmailAdminContentPay = templateAdminContentPay
                            //     .replace(/%%FirstName%%/g, firstName)
                            //     .replace(/%%LastName%%/g, lastName)
                            //     .replace(/%%Email%%/g, email)
                            //     .replace(/%%ActivationCode%%/g, activationCode)
                            //     .replace(/%%ClinicianName%%/g, getClinicianData.firstName + ' ' + getClinicianData.lastName)
                            //     .replace(/%%PAYMENTOPTION%%/g, '')
                            //     .replace(/%%WHOLESALE%%(.*?)%%WHOLESALE%%/gs, paymentOptionType === 'WHOLESALE' ? '$1' : '')
                            //     .replace(/%%UPFRONT%%(.*?)%%UPFRONT%%/gs, paymentOptionType === 'UPFRONT' ? '$1' : '')
                            //     .replace(/%%PURCHASE%%(.*?)%%PURCHASE%%/gs, paymentOptionType === 'PURCHASE' ? '$1' : '');

                            const updatedTemplate = replaceUrls(updatedEmailAdminContentPay, sessionUrl);

                            await sendEmail(email, clientTemplatePay[0].subject, updatedTemplate, clientTemplatePay[0].bcc, userprofileData.emailAddress);

                            setErrorMsg('');
                            setFirstName('');
                            setLastName('');
                            setEmail('');
                            setSuccessMsg('Patient added successfully!');
                            setIsLoading(false);
                            signOut(auth);

                        } else if(purchase === "purchase1" ){

                            const stripePromise = loadStripe(PUBLIC_KEY);

                            const stripe = await stripePromise;

                            //Send the email on basis of payment type selection
                            // const updatedEmailAdminContentPay = templateAdminContentPay
                            //     .replace(/%%FirstName%%/g, firstName)
                            //     .replace(/%%LastName%%/g, lastName)
                            //     .replace(/%%Email%%/g, email)
                            //     .replace(/%%ActivationCode%%/g, activationCode)
                            //     .replace(/%%ClinicianName%%/g, getClinicianData.firstName + ' ' + getClinicianData.lastName)
                            //     .replace(/%%PAYMENTOPTION%%/g, '')
                            //     .replace(/%%WHOLESALE%%(.*?)%%WHOLESALE%%/gs, paymentOptionType === 'WHOLESALE' ? '$1' : '')
                            //     .replace(/%%UPFRONT%%(.*?)%%UPFRONT%%/gs, paymentOptionType === 'UPFRONT' ? '$1' : '')
                            //     .replace(/%%PURCHASE%%(.*?)%%PURCHASE%%/gs, paymentOptionType === 'PURCHASE' ? '$1' : '');

                            await sendEmail(email, clientTemplatePay[0].subject, updatedEmailAdminContentPay, clientTemplatePay[0].bcc, userprofileData.emailAddress);

                            setErrorMsg('');
                            setFirstName('');
                            setLastName('');
                            setEmail('');
                            setSuccessMsg('Patient added successfully!');
                            setIsLoading(false);
                            signOut(auth);

                            const clinicianUID =  doc(db, `${DATABASECOLLECTION.USERPROFILES}/${localStorage.getItem('docid')}`)?.id
                            
                            const res = await axios.post(`${BASE_URL}checkOutSession`, { uid: clinicianUID,purchase,installments:false,currencyType:selectedCurrency,clientUID:userId });

                            const sessionId = res?.data?.data?.session_id?.id;

                            const { error } = await stripe.redirectToCheckout({ sessionId: sessionId });

                            console.debug({ error });
                            return true;
                        } else if(purchase === "purchase2") {
                            //Send the email on basis of payment type selection
                            // const updatedEmailAdminContentPay = templateAdminContentPay
                            //     .replace(/%%FirstName%%/g, firstName)
                            //     .replace(/%%LastName%%/g, lastName)
                            //     .replace(/%%Email%%/g, email)
                            //     .replace(/%%ActivationCode%%/g, activationCode)
                            //     .replace(/%%ClinicianName%%/g, getClinicianData.firstName + ' ' + getClinicianData.lastName)
                            //     .replace(/%%PAYMENTOPTION%%/g, '')
                            //     .replace(/%%WHOLESALE%%(.*?)%%WHOLESALE%%/gs, paymentOptionType === 'WHOLESALE' ? '$1' : '')
                            //     .replace(/%%UPFRONT%%(.*?)%%UPFRONT%%/gs, paymentOptionType === 'UPFRONT' ? '$1' : '')
                            //     .replace(/%%PURCHASE%%(.*?)%%PURCHASE%%/gs, paymentOptionType === 'PURCHASE' ? '$1' : '');

                            await sendEmail(email, clientTemplatePay[0].subject, updatedEmailAdminContentPay, clientTemplatePay[0].bcc, userprofileData.emailAddress);

                            setErrorMsg('');
                            setFirstName('');
                            setLastName('');
                            setEmail('');
                            setSuccessMsg('Patient added successfully!');
                            setIsLoading(false);
                            signOut(auth);

                            const clinicianUID =  doc(db, `${DATABASECOLLECTION.USERPROFILES}/${localStorage.getItem('docid')}`)?.id

                           
                            const res = await axios.post(`${BASE_URL}checkOutSession`, { uid: clinicianUID,purchase,installments:false,currencyType:selectedCurrency,clientUID:userId });


                            return true;
                        }
                        else if(purchase === "purchase5") {
                            await sendEmail(email, clientTemplatePay[0].subject, updatedEmailAdminContentPay, clientTemplatePay[0].bcc, userprofileData.emailAddress);

                            setErrorMsg('');
                            setFirstName('');
                            setLastName('');
                            setEmail('');
                            setSuccessMsg('Patient added successfully!');
                            setIsLoading(false);
                            signOut(auth);

                            return true;
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        setErrorMsg('Something went wrong!. Please try again.');
                    })
            } else {
                setErrorMsg('This email is already in use. Please use a different email.');
            }
        } catch (error) {
            setIsLoading(false);
            if (error.code === 'auth/email-already-in-use') {
                setErrorMsg('This email is already in use. Please use a different email.');
            } else {
                setErrorMsg('Something went wrong!. Please try again.');
            }
        }

    };

    return (
        <>
            <div className="dashboardWrapper">
                <AdimnSidebar />
                <div className="adminDashBoard">
                    <AdminHeader />
                    <div className="adminBody">
                        <div className="registerUser commonCard">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-header ">
                                        <div className="SectionHeadingMain">
                                            Add Client & Invoice
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {errorMsg && (
                                        <Alert text={errorMsg} type="error" />
                                    )}
                                    {successMsg && (
                                        <Alert text={successMsg} type="success" />
                                    )}
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text"
                                                        className="form-control shadow-none border"
                                                        id="firstName"
                                                        name="firstName"
                                                        placeholder=""
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                    />
                                                    <label htmlFor="floatingInput" className='formControlLable'>
                                                        <div className="labelTxt">
                                                            First Name
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-floating mb-3">
                                                    <input type="text"
                                                        className="form-control shadow-none border"
                                                        id="lastname"
                                                        name="lastName"
                                                        placeholder=""
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                    />
                                                    <label htmlFor="floatingInput" className='formControlLable'>
                                                        <div className="labelTxt">
                                                            Last Name
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-floating mb-3">
                                                    <input type="email"
                                                        className="form-control shadow-none border"
                                                        id="email"
                                                        name="email"
                                                        placeholder=""
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    <label htmlFor="floatingInput" className='formControlLable'>
                                                        <div className="labelTxt">
                                                            Email
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className='row'  >
                                        <div className="col-12 col-sm-6  mb-2 ">
                                            <select value={selectedCurrency} onChange={(e)=>setSelectedCurrency(e.target.value)} className='form-select'  >
                                                <option value=""  >Please select a currency</option>
                                                <option value="AUD"  >AUD</option>
                                                <option  value="USD" >USD</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'  >
                                        <div className="col-12 col-sm-6  mb-2 ">
                                            <select value={purchase} onChange={(e)=>setPurchase(e.target.value)} className='form-select'  >
                                                <option value=""  >Please select a product</option>
                                                {availableProducts}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-8 col-sm-6 col-md-3 col-xl-3 mx-auto mx-sm-0">
                                    <div className="signInButton mt-3 mt-md-3 text-center">
                                        {!isLoading && (
                                            <button className='signinBtn rounded-2' type='btn' onClick={handleSubmit}>Add Client & Invoice</button>
                                        )}
                                    </div>
                                </div> */}
                                {/*<div className="row">
                                    <div className="col-12 col-sm-6  mb-2 ">
                                        <select value={purchase} onChange={(e)=>setPurchase(e.target.value)} className='form-select'  >
                                            <option value=""  >Please select a product</option>
                                            {availableProducts}
                                        </select>
                                    </div>
                                    {/*<div className='col-md-6'>
                                        <div className="border p-3">
                                            <p className='text-center text-uppercase'>Clinician Purchase</p>
                                            <div className='row d-flex justify-content-center'>
                                                <div className='col-md-9'>
                                                    <label className='payment-option mb-2'>
                                                        <input
                                                            type="radio"
                                                            name="purchase"
                                                            className="card-input-element d-none"
                                                            value="purchase1"
                                                            onChange={(e) => setPurchase(e.target.value)}
                                                        />
                                                        <div className="card card-body bg-light d-flex flex-row justify-content-between align-items-center p-3">
                                                            Wholesale Purchase – Purchase now
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='col-md-9'>
                                                    <label className="payment-option">
                                                        <input
                                                            type="radio"
                                                            name="purchase"
                                                            className="card-input-element d-none"
                                                            value="purchase2"
                                                            onChange={(e) => setPurchase(e.target.value)}
                                                        />
                                                        <div className="card card-body bg-light d-flex flex-row justify-content-between align-items-center p-3">
                                                            Wholesale Purchase – Invoice me
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(localStorage.getItem('role') === ROLES.CLINICIANINHOUSE || localStorage.getItem('role') === ROLES.ADMIN) && (
                                        <div className='col-md-6'>
                                            <div className="border p-3">
                                                <p className='text-center text-uppercase'>Client Purchase</p>
                                                <div className='row d-flex justify-content-center'>
                                                    <div className='col-md-9'>
                                                        <label className='payment-option mb-2'>
                                                            <input
                                                                type="radio"
                                                                name="purchase"
                                                                className="card-input-element d-none"
                                                                value="purchase3"
                                                                onChange={(e) => setPurchase(e.target.value)}
                                                            />
                                                            <div className="card card-body bg-light d-flex flex-row justify-content-between align-items-center p-3">
                                                                Client Purchase – Upfront Payment
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <label className="payment-option">
                                                            <input
                                                                type="radio"
                                                                name="purchase"
                                                                className="card-input-element d-none"
                                                                value="purchase4"
                                                                onChange={(e) => setPurchase(e.target.value)}
                                                            />
                                                            <div className="card card-body bg-light d-flex flex-row justify-content-between align-items-center p-3">
                                                                Client Purchase – 6 x Monthly Instalments
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>*/}
                                {!isLoading ? (
                                    <div className="col-8 col-sm-6 col-md-3 col-xl-3 mx-auto mx-sm-0">
                                        <div className="signInButton mt-3 mt-md-3 text-center">
                                            <button className='signinBtn rounded-2 addPatientBtn' type='btn' onClick={handleSubmit} disabled={isButtonDisabled}>Add Client & Invoice</button>
                                        </div>
                                    </div>
                                ) :
                                    <div className="col-md-3 my-3">
                                        {isLoading && <Loader isEnquieySpinner={true} />}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPatient
