import React, { useState } from 'react'
import { db } from './firebase';
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import axios from 'axios';
import { BASE_URL } from './fetch';

const userProfiles = collection(db, 'userProfiles');
const allUsers = collection(db, 'allUsers');
const template = collection(db, 'templates');

// export const checkEmailExists = async (email) => {
//   const qu = query(userProfiles, where('emailAddress', '==', email));
//   const queryEmailSnapshot = await getDocs(qu);
//   return !queryEmailSnapshot.empty;
// };


export const checkEmailExists = async (email) => {
  return axios.get(`${BASE_URL}checkEmail?email=` + email)
  .then(response => response?.data?.user)
  .catch(error => error?.response?.data?.user);
};


export const getAuthDetails = async (email) => {
  const q = query(userProfiles, where('emailAddress', '==', email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return null;
  }
  const docId = querySnapshot.docs[0].id;
  const docData = querySnapshot.docs[0].data();
  return { docId, docData };
};

export const getUserName = async (email) => {
  const q = query(userProfiles, where('emailAddress', '==', email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return null;
  }
  const docData = querySnapshot.docs[0].data();
  return docData.firstName + ' ' + docData.lastName;
};

/* Generate Random Code */
export const RandomCodeGenerator = () => {
  const randomNumber = Math.floor(Math.random() * 1000000);
  const formattedCode = randomNumber.toString().padStart(6, '0');
  return formattedCode;
}

export const getDocsWithPagination = async (collectionRef, pageNumber, pageSize) => {
  const startIndex = (pageNumber - 1) * pageSize;
  const limit = pageSize;
  const query = collectionRef.limit(limit).startAt(startIndex);
  const querySnapshot = await getDocs(query);
  return querySnapshot;
};

/* to get template content  */
export const getTemplateContent = async (type) => {
  const qu = query(template, where('type', '==', type));
  const querySnapshot = await getDocs(qu);
  const templateData = querySnapshot.docs.map((doc) => doc.data());

  return templateData;
};

/* to create event logs in userEvents collection  */
export const createEvent = async (data) => {
  const userEventDocRef = doc(collection(db, 'userEvents'), data.userId);
  const checkExistUserEvent = await getDoc(userEventDocRef);

  if (!checkExistUserEvent.exists()) {
    await setDoc(userEventDocRef, {});
  }
  const eventsCollectionRef = collection(userEventDocRef, 'events');
  await addDoc(eventsCollectionRef, {
    version: '1.0',
    date: serverTimestamp(),
    source: 'web',
    type: data.type,
    details: data.details,
  });

};

/* helper function to catch firestore transaction errors:
    firebaseFunc: A firebase function
    ...args: The firebase functions arguments */
export const firestoreTransaction = async (firebaseFunc, ...args) => {
  try {
    const funcCall = await firebaseFunc(...args);
    return funcCall;
  }
  catch (error) {
    const errorMessage = `Error in firestore transaction: ${error.message}`;
    console.error(errorMessage)
    throw errorMessage;
  }
};